import React from "react";
import { Col, Drawer, Tooltip } from "antd";
import "./DrawerMenu.scss";
import { withRouter } from "react-router-dom";
import TimeAgo from "../misc/timeStamp";

const NotificationsDrawer = (props) => {
  const { visible, onClose, data } = props;

  const length = data?.data?.length;

  const handleNavigate = (message) => {
    window.location.href = message.link;
  };
  return (
    <Drawer
      title={`${length} Notifications`}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
    >
      {Array.isArray(data.data) &&
        (data?.data).map((data) => (
          <Col className="container">
            <Tooltip title="Click to view">
              <div
                className="rowContainer"
                onClick={() => handleNavigate(data)}
              >
                <Col className="message">
                  <p>{data.message}</p>
                </Col>
                <Col className="timeStamp">
                  <p> {TimeAgo(data.created_at)}</p>
                </Col>
              </div>
            </Tooltip>
          </Col>
        ))}
    </Drawer>
  );
};

export default withRouter(NotificationsDrawer);
