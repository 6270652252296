import React from "react";
import "./index.scss";
// import App from './components/App';
import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserHistory } from "history";
import ActionCableProvider from "react-actioncable-provider";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import App from "./components/App";
import { API_WS_ROOT } from "./components/misc/Axios";
import reducer from "./reducers";
import { authSaga } from "./sagas/authentication_saga";
//import reducerStore from "./reducers";
const sagaMiddleware = createSagaMiddleware();
export const store = createStore(reducer, applyMiddleware(sagaMiddleware));
export const history = createBrowserHistory({ forceRefresh: true });
export const no_refresh_history = createBrowserHistory({ forceRefresh: false });

sagaMiddleware.run(authSaga);

const container = document.getElementById('root');
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <ActionCableProvider url={API_WS_ROOT}>
      <App />{" "}
    </ActionCableProvider>
  </Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
