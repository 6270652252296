import React from "react";
import { Col, Row } from "reactstrap";
import "./CompanyBlock.scss";
import Logo from "../../assets/images/Steman-Commerce-Full-Logo-small.png";

const CompanyBlock = () => {
  return (
    <Col id="companyBlock">
      <Row>
        <Col md={12} className="companyLogo">
          <div className="img">
            <img src={Logo} alt="Company logo" />
          </div>
        </Col>
        <Col md={12} className="companyName"></Col>
      </Row>
    </Col>
  );
};

export default CompanyBlock;
