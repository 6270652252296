import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Row, Col, Card, CardTitle, CardText } from "reactstrap";
import axiosInstance from "../misc/Axios";
import NumberField from "../Controls/NumberField";
import { LineChart } from "react-chartkick";
import "chartkick/chart.js";


const Dashboard = () => {
  const [state, setState] = useState({
    salesTrend: [],
    ordersTrend: [],
    pendingOrders: 0,
    productsCount: 0,
    accountBalance: 0,
    totalSales: 0,
    loading: false,
  })
  const dashInfo = [
    { name: "Products", value: state.productsCount },
    { name: "Orders Pending", value: parseInt(state.pendingOrders) },
    { name: "Total Sales (GHS)", value: state.totalSales },
    {
      name: "Account Balance (GHS) ",
      value: `GHS ${state.accountBalance}`,
      type: "number",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [pendingOrdersResponse, productsCountResponse, totalSalesResponse, salesTrendResponse, ordersTrendResponse, accountBalanceResponse] = await Promise.all([
          axiosInstance.get("/partners/dashboard/orders_pending"),
          axiosInstance.get("/partners/dashboard/products_count"),
          axiosInstance.get("/partners/dashboard/total_sales"),
          axiosInstance.get("/partners/dashboard/sales_trend"),
          axiosInstance.get("/partners/dashboard/orders_trend"),
          axiosInstance.get("/partners/dashboard/account_balance"),
        ]);

        console.log(pendingOrdersResponse, productsCountResponse)
        setState({
          loading: false,
          pendingOrders: pendingOrdersResponse.data.data,
          productsCount: productsCountResponse.data.data,
          totalSales: totalSalesResponse.data.data,
          salesTrend: salesTrendResponse.data.data,
          ordersTrend: ordersTrendResponse.data.data,
          accountBalance: accountBalanceResponse.data.data,
        });
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    setTimeout(fetchData, 1000);
  }, []);

  return (
    <Col className="dashboardContent">
      <Row>
        {dashInfo.map((info, i) => {
          return (
            <Col md={3} className="infoBox">
              <Col className="inner">
                <Row>
                  <Col className="h-100 value">
                    {info.type === "number" ? (
                      <NumberField value={info.value} />
                    ) : (
                      info.value
                    )}
                  </Col>
                </Row>
                <Col className="bottom">{info.name}</Col>
              </Col>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col md={6}>
          <Card body>
            <CardTitle>Sales in the Last 7 Days</CardTitle>
            <CardText>
              <LineChart
                data={state.salesTrend}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5,
                }}
              />
            </CardText>
          </Card>
        </Col>
        <Col md={6}>
          <Card body>
            <CardTitle>Orders in the Last 7 Days</CardTitle>
            <CardText>
              <LineChart
                data={state.ordersTrend}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              />
            </CardText>
          </Card>
        </Col>
      </Row>
    </Col>
  );
}


export default Dashboard


