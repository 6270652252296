import React, { useMemo } from 'react';
import Select from 'react-select';

const CustomSelect = (props) => {
    const customStyles = useMemo(() => ({
        option: (provided, state) => ({
            ...provided,
            padding: 7,
        }),
        control: () => ({
            alignItems: "center",
            backgroundColor: "hsl(0,0%,100%)",
            borderColor: "hsl(0,0%,80%)",
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "default",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            outline: "0 !important",
            position: "relative",
            transition: "all 100ms",
            boxSizing: "border-box",
        }),
        valueContainer: (styles) => ({
            ...styles,
            height: 25,
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            height: 30,
            width: 30,
        }),
        singleValue: (provided, state) => ({
            ...provided,
            opacity: 1,
            transition: 'opacity 300ms',
        }),
    }), []);

    return <Select styles={customStyles} {...props} />;
};

export default CustomSelect;
