import "./AuthLayout.scss";
import Logo from "../../assets/images/Steman-Commerce-Full-Logo-small.png";
import { Col } from "reactstrap";
import React from "react";
import { Link } from "react-router-dom";

const AuthLayout = (props) => {
  return (
    <div className="row  h-100">
      <div className="col-md-12 loginHeader"></div>
      <Link to="/">
        <div id="floatingLogo">
          <img src={Logo} alt="Steaman logo" />
        </div>
      </Link>
      <Col md={12}>
        <div id="" className=" justify-content-center row ">
          {" "}
          {props.children}
        </div>
      </Col>
    </div>
  );
};

export default AuthLayout;
