/* eslint-disable react/function-component-definition */

const TimeAgo = (timestamp) => {
  const now = new Date();
  const time = new Date(timestamp);
  const timeDiff = now.getTime() - time.getTime();
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) {
    return `${months} ${months === 1 ? "month" : "months"} ago`;
  }
  if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  }
  if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  }
  if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  }
  return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
};

export default TimeAgo;
