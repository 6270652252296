import React from "react";
import { Drawer } from "antd";
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";
//import styles from "./styles.module.scss";
import Navigation from "../Sidebar/Navigations/Navigation";
import "./nav_styles.scss";
//import MobileNavigation from "../Sidebar/Navigations/MobileNavigation";

export default function MobileNavMenu({ open, onClose }) {
  return (
    <Drawer
      bodyStyle={{
        padding: 0,
        margin: 0,
      }}
      title={<Header onClose={onClose} />}
      placement="left"
      closable={false}
      onClose={() => {}}
      visible={open}
      key="left"
    >
      <div className="side_menu_bar">
        <Navigation handleClose={onClose} />
      </div>
    </Drawer>
  );
}

function Header({ onClose }) {
  return (
    <header className="menu_header">
      <Link to="/">
        <img
          src="/apple-icon-72x72.png"
          alt="Steaman"
          className="header__logo"
        />
      </Link>

      <MdClose size={30} onClick={onClose} />
    </header>
  );
}
