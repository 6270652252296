import React from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
const PasswordEditForm = (props) => {
  return (
    <Form id={props.id} className="col-md-12" onSubmit={props.password_edit}>
      <FormGroup>
        <Label for="examplePassword">New Password</Label>
        <Input
          type={props.showPassword ? "text" : "password"}
          name="password"
          id="password"
          value={props.password}
          onChange={props.onChange}
          placeholder="Password"
        />
      </FormGroup>
      <FormGroup>
        <Label for="examplePassword">Confirm New Password</Label>
        <Input
          type={props.showPassword ? "text" : "password"}
          name="passwordConfirmation"
          id="password_confirmation"
          value={props.passwordConfirmation}
          onChange={props.onChange}
          placeholder="Password"
        />
      </FormGroup>
      <p className="btn btn-info" onClick={props.handleShowPassword}>
        show password
      </p>
      <Row>
        <Col md={4}>
          <Button
            color={"success"}
            disabled={props.loading}
            onClick={props.password_edit}
            className={"form-control"}
          >
            {props.loading ? (
              <ScaleLoader
                size={5}
                height={15}
                color={"#ffffff"}
                loading={true}
              />
            ) : (
              "Change Password"
            )}
          </Button>
        </Col>
        <Col md={8} className="resetPassword">
          <p>
            Forgot your password?{" "}
            <Link to="/forgot_password">Reset Password</Link>
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="signup">
          <p>
            Don't have an account? <Link to="/sign_up">Sign up</Link> or{" "}
            <Link to="/confirm_email">Confirm Your Email</Link>
          </p>
        </Col>
      </Row>
    </Form>
  );
};

export default PasswordEditForm;
