import React from 'react'
import './CurrentUser.scss'
import * as actions from '../../actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserImg from '../../assets/images/user-placeholder.png'

const CurrentUser = (props) => {
    return (

        <div id="currentUser" md={12}>

            <p className='userInfo'>
                <span id='userName' className='inline' >{props.authentication.user.first_name.trim()}</span> &nbsp;<span id='userImg' className='inline'>
                    <img src={UserImg} alt='User profile' /></span>
            </p>


        </div>

    )
}

const mapStateToProps = state => ({ authentication: state.authentication })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrentUser);

