import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import { FaBell, FaEnvelope, FaBars } from "react-icons/fa";
import axiosInstance from "../misc/Axios";

import "./MainAppbar.scss";
import CurrentUser from "./CurrentUser";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PartnerModal from "../Modals/PartnerModal";
import ChangePasswordModal from "../Modals/ChangePasswordModal";
import MobileNavMenu from "../MobileNavMenu";
import NotificationsDrawer from "../DrawerMenu";
import { Badge } from "antd";

const MainAppNavbar = (props) => {
  const [state, setState] = useState({
    isOpen: false,
    showPartnersModal: false,
    mobileMenuOpen: false,
    drawerVisible: false,
    notificationsData: [],
  });

  useEffect(() => {
    const fetchNotifications = async () => {
      if (props.authentication.default_path !== "/admins") {
        return;
      }

      try {
        const response = await axiosInstance.get(
          `${props.authentication.default_path}notifications`
        );
        setState((prevState) => ({
          ...prevState,
          notificationsData: response?.data,
        }));
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    const notificationTimeout = setTimeout(fetchNotifications, 200);

    return () => clearTimeout(notificationTimeout);
  }, [props.authentication.default_path]);

  const logout = () => {
    props.actions.logout();
  };

  const togglePartnersModal = () => {
    setState((prevState) => ({
      ...prevState,
      showPartnersModal: !state.showPartnersModal,
    }));
  };
  const togglePasswordModal = () => {
    setState((prevState) => ({
      ...prevState,
      showPasswordModal: !prevState.showPasswordModal,
    }));
  };
  const switchToAdmin = () => {
    axiosInstance
      .get(`/admins/partners/companies/switch_to_admin`)
      .then((response) => {
        var newAdminUser = response.data.current_admin;
        props.actions.UpdateUser(newAdminUser);

        setState((prevState) => ({
          ...prevState,
          showPartnersModal: false,
        }));
      });
  };

  const selectPartner = (partner) => {
    axiosInstance
      .get(`/admins/partners/companies/${partner}/switch_company`)
      .then((response) => {
        var newPartnerUser = response.data.current_admin;
        props.actions.UpdateUser(newPartnerUser);

        setState((prevState) => ({
          ...prevState,
          showPartnersModal: false,
        }));
      });
  };
  const openDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      drawerVisible: true,
    }));
  };
  const closeDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      drawerVisible: false,
    }));
  };
  return (
    <Container fluid className="header">
      <PartnerModal
        isOpen={state.showPartnersModal}
        selectPartner={selectPartner}
        switchToAdmin={switchToAdmin}
        toggle={togglePartnersModal}
      />
      <ChangePasswordModal
        isOpen={state.showPasswordModal}
        toggle={togglePasswordModal}
      />
      <Navbar light expand="xs" dark={true} className="mainApp">
        <Nav className="navbar-nav" navbar>
          <div className="mobile_nav">
            <MobileNavMenu
              open={state.mobileMenuOpen}
              onClose={() => {
                setState({
                  ...state,
                  mobileMenuOpen: false,
                });
              }}
            />
          </div>
          <div className="mobileMenuIcon">
            <FaBars
              size={18}
              color="black"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  mobileMenuOpen: true,
                }))
              }
            />
          </div>
          <NavItem>
            <NavLink>
              <p className="current_partner">
                {props.authentication.user.current_company
                  ? ` Current Partner :  ${props.authentication.user.current_company.name}`
                  : ""}
              </p>
            </NavLink>
          </NavItem>
          <UncontrolledDropdown inNavbar>
            <DropdownToggle color="default">
              <FaEnvelope />
            </DropdownToggle>
            <DropdownMenu className="" right altclass="largeDropdown">
              <DropdownItem>You have no messages</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown inNavbar>
            <DropdownToggle color="default" onClick={openDrawer}>
              <Badge count={state.notificationsData?.data?.length}>
                <FaBell />
              </Badge>
            </DropdownToggle>
            <DropdownMenu className="" right>
              <DropdownItem right>
                {state.notificationsData?.data?.length > 0 ? (
                  <>
                    You have {state.notificationsData?.data?.length} new
                    notifications
                  </>
                ) : (
                  "You have No Notifications"
                )}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NotificationsDrawer
            visible={state.drawerVisible}
            onClose={closeDrawer}
            data={state.notificationsData}
          />

          <UncontrolledDropdown inNavbar style={{}}>
            <DropdownToggle color="default">
              <CurrentUser />
              {/* <FaCog /> */}
            </DropdownToggle>

            <DropdownMenu right>
              <DropdownItem onClick={togglePasswordModal}>
                Change Password
              </DropdownItem>
              {props.authentication.default_path === "/admins/" ? (
                <DropdownItem onClick={togglePartnersModal}>
                  Switch to Partner
                </DropdownItem>
              ) : (
                ""
              )}

              <DropdownItem onClick={logout}>Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    </Container>
  );
};
const mapStateToProps = (state) => ({ authentication: state.authentication });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainAppNavbar)
);
