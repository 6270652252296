import React, { Component } from 'react'
import CompanyBlock from '../CompanyBlock/CompanyBlock'
import Navigation from './Navigations/Navigation'
import './Sidebar.scss'
export default class Sidebar extends Component {
    render() {

        return (
            <nav className='sidebar'>

                <CompanyBlock />
                <Navigation />
            </nav >
        )
    }
}
