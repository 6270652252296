import React, { useState } from "react";
import AuthLayout from "../Layout/AuthLayout";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { baseurl } from "../misc/Axios";
import axios from "axios";
import { toast } from "react-toastify";
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPassword = () => {
  const [state, setState] = useState({
    email: "",
    errors: [],
    message: "",
    confirming: false,
    passwordChanged: false,
    notFound: false,
  });
  const resetPassword = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      resetting: true,
    }));
    axios
      .post(baseurl + "/partners/password", {
        email: state.email,
        redirect_url: "/",
      })
      .then((response) => {
        if (response.status == 200) {
          toast.success(response.data?.message);
          setState((prevState) => ({
            ...prevState,
            email: "",
            message: response.data.message,
            resetting: false,
            passwordChanged: true,
          }));
        }
      })
      .catch((error) => {
        toast.error("Confirmation Resend Failed");
        setState((prevState) => ({
          ...prevState,
          resetting: false,
          errors: error.response.data.errors,
        }));
      });
  };
  const onChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      errors: [],
    }));
  };
  return (
    <AuthLayout>
      <div className="row">
        <div className="col-md-12 header">
          <h2>Reset Password</h2>
          {state.errors && state.errors.length > 0 && (
            <div className="alert alert-danger" role="alert">
              {state.errors}
            </div>
          )}{" "}
          {state.message && (
            <div className="alert alert-success" role="alert">
              {state.message}
            </div>
          )}
        </div>{" "}
        {state.passwordChanged ? (
          <div> {state.message} </div>
        ) : (
          <ForgotPasswordForm
            id="ForgotPasswordBox"
            {...state}
            onChange={onChange}
            resetPassword={resetPassword}
            resetting={state.resetting}
          />
        )}
      </div>
    </AuthLayout>
  );
};
const mapStateToProps = (state) => ({ authentication: state.authentication });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
