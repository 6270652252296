import React, { useEffect, useState } from "react";
import PasswordEditForm from "./PasswordEditForm";
import { toast } from "react-toastify";
import axios_original from "axios";
import { baseurl } from "../misc/Axios";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import AuthLayout from "../Layout/AuthLayout";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import { renderErrorsAsList } from "../misc/functions";

const PasswordEdit = () => {
  const [state, setState] = useState({
    password: "",
    passwordConfirmation: "",
    notFound: false,
    passwordChanged: false,
    loading: false,
    resetting: false,
    showConfirmed: false,
    showPassword: false,
  });

  const handleShowPassword = () => {
    setState((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    var resetPasswordToken = null;
    if (params.has("reset_password_token")) {
      resetPasswordToken = params.get("reset_password_token");
    }

    setState({
      ...state,
      resetPasswordToken: resetPasswordToken,
    });
  }, []);

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      notFound: false,
    });
  };
  const handlePasswordEdit = (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });
    axios_original
      .put(baseurl + "/partners/password", {
        password: state.password,
        password_confirmation: state.passwordConfirmation,
        reset_password_token: state.resetPasswordToken,
        // client_resetPasswordToken: state.resetPasswordToken,
        redirect_url: "/",
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setState({
            ...state,
            email: "",
            message: response.data.message,
            loading: false,
            password_changed: true,
          });
        }
      })
      .catch((error) => {
        let notFound = false;
        console.log(error);
        if (error.response.status === 401) {
          notFound = true;
        }
        toast.error(
          <ul>
            {renderErrorsAsList(error.response.data.errors.full_messages)}
          </ul>
        );
        setState({
          ...state,
          resetting: false,
          loading: false,
          notFound,
        });
      });
  };

  const showConfirmed = () => {
    return (
      <div className="row">
        <div className="col-md-12 header">
          <h2>Account Successfully Confirmed</h2>
        </div>
        <Col md={12}>
          <p>Click on the button below to password_edit to your account</p>
          <Link
            to="/"
            className="btn btn-success"
            onClick={() => {
              window.location.replace(
                window.location.protocol + "//" + window.location.host
              );
            }}
          >
            PasswordEdit
          </Link>
        </Col>
      </div>
    );
  };
  return (
    <AuthLayout>
      {state.showConfirmed ? (
        showConfirmed()
      ) : (
        <div className="row">
          <div className="col-md-12 header">
            <h2>Change Password </h2>
            {!_.isEmpty(state.errors) && (
              <div className="alert alert-danger " role="alert">
                {state.errors}{" "}
              </div>
            )}
            {state.notFound && (
              <div class="alert alert-danger " role="alert">
                Requested Path Not Found
              </div>
            )}

            {state.passwordChanged && (
              <div className="alert alert-success">
                Password Successfully Changed. Click on the Button Below to
                login
              </div>
            )}
          </div>

          {state.password_changed ? (
            <Col>
              <Link to="/" className="btn btn-success">
                Login
              </Link>{" "}
            </Col>
          ) : (
            <PasswordEditForm
              id="password_editBox"
              {...state}
              loading={state.loading}
              onChange={onChange}
              password_edit={handlePasswordEdit}
              handleShowPassword={handleShowPassword}
            />
          )}
        </div>
      )}
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({ authentication: state.authentication });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordEdit);
