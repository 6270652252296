import React, { useEffect, useState } from "react";
import "./Login.scss";
import AdminLoginForm from "./AdminLoginForm";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import AuthLayout from "../Layout/AuthLayout";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

const AdminLoginPage = (props) => {
  const { authentication, actions } = props;
  const [state, setState] = useState({
    email: "",
    password: "",
    showConfirmed: false,
  });
  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    actions.AdminLogin(state.email, state.password);
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    var showConfirmed = false;
    if (params.has("account_confirmation_success")) {
      showConfirmed = params.get("account_confirmation_success") === "true";
    }

    setState((prevState) => ({
      ...prevState,
      showConfirmed: showConfirmed,
    }));
  }, []);

  const showConfirmed = () => {
    return (
      <div className="row">
        <div className="col-md-12 header">
          <h2>Account Successfully Confirmed</h2>
        </div>
        <Col md={12}>
          <p>Click on the button below to login to your account</p>

          <Link
            to="/"
            className="btn btn-success"
            onClick={() => {
              window.location.replace(
                window.location.protocol + "//" + window.location.host
              );
            }}
          >
            Login
          </Link>
        </Col>
      </div>
    );
  };

  return (
    <AuthLayout>
      {state.showConfirmed ? (
        showConfirmed()
      ) : (
        <div className="col-md-4 col-sm-4 authDiv">
          <Col>
            <div className="col-md-12 header">
              <h2>Login</h2>
              {!_.isEmpty(authentication.loginErrorMessage) && (
                <div class="alert alert-danger " role="alert">
                  {authentication.loginErrorMessage}{" "}
                </div>
              )}
            </div>
          </Col>

          <AdminLoginForm
            id="loginBox"
            {...state}
            loading={authentication.loading}
            onChange={onChange}
            login={handleLogin}
            onlyLogin={true}
          />
        </div>
      )}
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({ authentication: state.authentication });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLoginPage);
