import React from "react";
import NumberFormat from "react-number-format";
import { Input } from "reactstrap";

const NumberField = (props) => {
  const formatValue = (val) => {
    const { labelText } = props;
    if (labelText === "Percentage discount (%)") {
      return `${val}`;
    } else {
      return val;
    }
  };
  const onValueChange = (values) => {
    const numericValue = parseFloat(values.value.replace(/[^0-9.-]+/g, ""));
    const formattedValue = formatValue(numericValue);

    if (typeof props.onValueChange === "function") {
      props.onValueChange({
        floatValue: numericValue,
        formattedValue: formattedValue,
      });
    }
  };
  const { labelText } = props;

  return (
    <NumberFormat
      customInput={Input}
      value={props.value}
      // className={`form-control ${props.className}`}
      displayType={props.displayType === "input" ? "input" : "text"}
      thousandSeparator={true}
      className={props.input ? "form-control right" : props.className || ""}
      fixedDecimalScale={true}
      suffix={labelText === "Percentage discount (%)" ? "%" : ""}
      onValueChange={onValueChange}
      disabled={props.readOnly}
      readOnly={props.readOnly}
    />
  );
};
export default NumberField;
