import moment from "moment";
import axiosInstance from "./Axios";


export async function getPartnerTypes() {
  try {
    const response = await axiosInstance.get("/partner_types");

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching partner types:", error);
    return [];
  }
}

export async function getOrderStatuses() {
  try {
    const response = await axiosInstance.get("/order_statuses");

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: `${row.code} - ${row.name}`,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching order statuses:", error);
    return [];
  }
}

export async function getCompanies(partner) {
  try {
    const endpoint = partner
      ? "/partners/companies"
      : "/admins/partners/companies?full=true";
    const response = await axiosInstance.get(endpoint);

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching companies:", error);
    return [];
  }
}
export async function getVehicleTypes() {
  try {
    const response = await axiosInstance.get("/vehicle_types");

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching vehicle types:", error);
    return [];
  }
}

export async function getDeliveryTypes() {
  try {
    const response = await axiosInstance.get("/delivery_types");
    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching delivery types:", error);
    return [];
  }
}

export async function getBanks() {
  try {
    const response = await axiosInstance.get("/banks");

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching banks:", error);
    return [];
  }
}
export async function getSizeGroups() {
  try {
    const response = await axiosInstance.get(`/size_groups?with_parent=true`);

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching size groups:", error);
    return [];
  }
}
export async function getIndirectCategories() {
  try {
    const response = await axiosInstance.get(
      `/categories?type=indirects&with_parent=false`
    );

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching indirect categories:", error);
    return [];
  }
}
export async function getGenders() {
  try {
    const response = await axiosInstance.get("/genders");

    if (response.status === 200) {
      return response.data.genders.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching genders:", error);
    return [];
  }
}
export async function getCustomerTypes() {
  try {
    const response = await axiosInstance.get("/customer_types");

    if (response.status === 200) {
      return response.data.customer_types.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching customer types:", error);
    return [];
  }
}

export function flatten(arr) {
  return arr.reduce((acc, item) => {
    acc.push(item);
    if (Array.isArray(item.children) && item.children.length > 0) {
      acc = acc.concat(flatten(item.children));
    }
    return acc;
  }, []);
}

export async function getProductTaxes() {
  try {
    const response = await axiosInstance.get("/product_taxes");

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: `${row.name} - ${row.rate}%`,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching product taxes:", error);
    return [];
  }
}
export async function getCategories() {
  try {
    const response = await axiosInstance.get(`/categories?with_parent=true`);

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
}

export async function getBrands() {
  try {
    const response = await axiosInstance.get(`/brands?with_parent=true`);

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching brands:", error);
    return [];
  }
}
export async function getIDTypes() {
  try {
    const response = await axiosInstance.get("/id_types");

    if (response.status === 200) {
      return response.data.id_types.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching ID types:", error);
    return [];
  }
}
export async function getMaritalStatuses() {
  try {
    const response = await axiosInstance.get("/marital_statuses");

    if (response.status === 200) {
      return response.data.marital_statuses.map((row) => ({
        value: row.id,
        label: row.name,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching marital statuses:", error);
    return [];
  }
}
export async function getAccounts() {
  try {
    const response = await axiosInstance.get(`/accounts`);

    if (response.status === 200) {
      return response.data.map((row) => ({
        value: row.uuid,
        label: `${row.code} - ${row.name} ${
          row.number ? " - " + row.number : ""
        }`,
        id: row.id,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching accounts:", error);
    return [];
  }
}
export async function getMobileNetworks() {
  try {
    const response = await axiosInstance.get(`/mobile_networks`);

    if (response.status === 200) {
      return response.data.mobile_networks.map((row) => ({
        value: row.id,
        label: `${row.name}`,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching mobile networks:", error);
    return [];
  }
}
export async function getPaymentModes() {
  try {
    const response = await axiosInstance.get(`/payment_modes?savable=true`);

    if (response.status === 200) {
      return response.data.payment_modes.map((row) => ({
        value: row.id,
        label: `${row.name}`,
        code: row.code,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching payment modes:", error);
    return [];
  }
}
export async function getPaymentMethods() {
  try {
    const response = await axiosInstance.get(`/users/payment_methods`);

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: ` ${row.mobile_network} - ${row.number}`,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching payment methods:", error);
    return [];
  }
}
export async function getBankCashAccounts() {
  try {
    const response = await axiosInstance.get(
      `/accounts/?type=bank_cash_accounts`
    );

    if (response.status === 200) {
      return response.data.map((row) => ({
        value: row.id,
        label: `${row.name}`,
        code: row.code,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching bank cash accounts:", error);
    return [];
  }
}
export async function getCountries() {
  try {
    const response = await axiosInstance.get(`/countries/`);

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: `${row.name}`,
        phone_code: row.phone_code,
        uuid: row.uuid,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching countries:", error);
    return [];
  }
}
export async function getRegions(country_id) {
  try {
    const response = await axiosInstance.get(
      `/regions/?country_id=${country_id}`
    );

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: `${row.name}`,
        uuid: row.uuid,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching regions:", error);
    return [];
  }
}

export async function getCities(region_id) {
  try {
    const response = await axiosInstance.get(`/cities/?region_id=${region_id}`);

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: `${row.name}`,
        phone_code: row.phone_code,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching cities:", error);
    return [];
  }
}
export async function getCompanyTypes() {
  try {
    const response = await axiosInstance.get(`/company_types/`);

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: `${row.name}`,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching company types:", error);
    return [];
  }
}
export async function getLedgers() {
  try {
    const response = await axiosInstance.get(`/ledgers/`);

    if (response.status === 200) {
      return response.data.data.map((row) => ({
        value: row.id,
        label: `${row.code} - ${row.name}`,
      }));
    } else {
      console.error("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching ledgers:", error);
    return [];
  }
}
export function formatDate(date) {
  return moment(date).format("dddd, MMMM Do YYYY");
}
export function renderErrorsAsList(errors) {
  return errors.map((err, i) => <li key={i}>{err}</li>);
}

export function formatDateTime(date) {
  return moment(date).format("dddd, MMMM Do YYYY @ h:mm:ss a");
}

export function numberToCurrency(num) {
  return Intl.NumberFormat("en-GH", {
    currency: "GHS",
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
}
