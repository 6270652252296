import React, { Component } from "react";
// import logo from './logo.svg';
import "./App.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PartnerLogin from "./Login/PartnerLogin";

import ConfirmingSpinner from "../components/ConfirmingSpinner/ConfirmingSpinner";
import axios from "../components/misc/Axios";
import confidentSfx from "../sfx/confident.mp3";
// import Signup from '../components/Signup'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as actions from ".././actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "../components/Layout/Layout";
import UserSignup from "./Signup/UserSignup";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ConfirmEmail from "./ConfirmEmail/ConfirmEmail";
import PasswordEdit from "./PasswordEdit/PasswordEdit";
import PartnerSignup from "./Signup/PartnerSignup";
import { ActionCableConsumer } from "react-actioncable-provider";
import AdminLoginPage from "./Login/AdminLogin";

class App extends Component {
  state = {
    confirmingLoggedIn: false,
  };
  // componentDidMount = () => {
  //   // Retrieve notifications from localStorage
  //   const storedNotifications = JSON.parse(
  //     localStorage.getItem("notifications")
  //   );
  //   if (storedNotifications) {
  //     const storedNotifications = JSON.parse(
  //       localStorage.getItem("notifications")
  //     );

  //     // Check if storedNotifications is an array
  //     if (Array.isArray(storedNotifications)) {
  //       // Iterate over storedNotifications and add each notification to Redux state
  //       storedNotifications.forEach((notification) => {
  //         this.props.addNotification(notification);
  //       });
  //     }
  //   }
  //   if (this.isLoggedIn == false) {
  //     axios
  //       .get("/")
  //       .then((response) => {
  //         console.log(response);
  //       })
  //       .catch((errors) => {
  //         console.log(errors);
  //       });
  //   } else {
  //     this.setState({
  //       ...this.state,
  //       confirmingLoggedIn: false,
  //     });
  //   }
  // };
  notification_received = (msg) => {
    var audio = new Audio(confidentSfx);
    audio.play();
    console.log("SOMETHING RECEIVED", msg);
    toast.success(
      <p>
        {msg.msg} <br />
        <a href={msg.link} className="btn btn-xs btn-primary form-control">
          View
        </a>
      </p>,
      { autoClose: false }
    );
    // this.props.addNotification(msg);
    // const storedNotifications =
    //   JSON.parse(localStorage.getItem("notifications")) || [];

    // // Ensure storedNotifications is an array
    // const existingNotifications = Array.isArray(storedNotifications)
    //   ? storedNotifications
    //   : [];

    // // Add the new notification to the array
    // const updatedNotifications = [...existingNotifications, msg];

    // // Save the updated array back to localStorage
    // localStorage.setItem("notifications", JSON.stringify(updatedNotifications));

    // localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
  };
  getLayoutOrRedirect = () => {
    console.log(this.props.authentication);
    return (
      <>
        {this.props.authentication.default_path == "/admins/" ? (
          <ActionCableConsumer
            channel={{
              channel: "AdminNotificationsChannel",
              id: this.props.authentication.user.uuid,
            }}
            onReceived={this.notification_received}
            onConnected={() => {
              console.log("WEBCONNECTED TO WEBSOCKET");
            }}
            onRejected={() => {
              console.log("WEBCONNECTION DISCONNECTED");
            }}
          />
        ) : (
          ""
        )}{" "}
        <Layout />{" "}
      </>
    );
  };
  isLoggedIn = () => {
    // console.log("IS LOGGED IN", this.props.authentication.loggedIn);
    if (
      this.props.authentication.loggedIn == "true" ||
      this.props.authentication.loggedIn == true
    ) {
      return true;
    }
    return false;
  };
  isAdmin = () => {
    if (window.location.pathname == "/admins") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <Router>
        <div className="App container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-12">
              <ToastContainer autoClose={2000} />
              <Switch>
                <Route exact path="/partners/sign_up">
                  <PartnerSignup />
                </Route>

                <Route
                  exact
                  path="/partners/forgot_password"
                  location={this.props.location}
                >
                  <ForgotPassword />
                </Route>
                <Route
                  exact
                  path="/partners/confirm_email"
                  location={this.props.location}
                >
                  <ConfirmEmail />
                </Route>
                <Route
                  exact
                  path="/partners/password_edit"
                  location={this.props.location}
                >
                  <PasswordEdit />
                </Route>

                {this.isLoggedIn() == false ? (
                  this.isAdmin() == true ? (
                    <AdminLoginPage location={this.props.location} />
                  ) : (
                    <PartnerLogin location={this.props.location} />
                  )
                ) : this.state.confirmingLoggedIn == true ? (
                  <ConfirmingSpinner />
                ) : (
                  this.getLayoutOrRedirect()
                )}
                <Route exact path="/admins/">
                  <AdminLoginPage location={this.props.location} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  // notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  // addNotification: bindActionCreators(addNotification, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
