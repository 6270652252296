import React, { useState } from "react";
import { Col, FormGroup, Input, Button } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import axiosInstance from "../misc/Axios";
import { Array } from "core-js";

const PartnerSearch = (props) => {
  const [state, setState] = useState({
    partners: [],
    searchString: "",
  });

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .get(`/admins/partners/companies?search_string=${state.searchString}`)
      .then((response) => {
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            partners: [...response.data.data],
          }));
        }
      });
  };

  return (
    <Col>
      <form className="form-horizontal" onSubmit={onSubmit}>
        <FormGroup row>
          <div className="col-xl-12 input-group">
            <Input
              type="name"
              name="searchString"
              placeholder="Search for Partner by number,  name, email and phone number"
              onChange={onChange}
              value={state.searchString}
              readOnly={state.readonly}
            />

            <div class="input-group-append">
              <Button type="submit" color="primary">
                <FaSearch />
              </Button>
            </div>
          </div>
        </FormGroup>
      </form>
      <FormGroup>
        <Button
          className={"form-control"}
          color={"primary"}
          onClick={props.switchToAdmin}
        >
          Switch Back to Admin
        </Button>
      </FormGroup>
      <table className="table table-striped my-4 w-100 table-bordered">
        <thead>
          <tr>
            <th width="5%" data-priority="1">
              Index
            </th>
            <th width="55%" className="sort-alpha" data-piority="2">
              Name
            </th>
            <th width="20%" className="sort-alpha" data-priority="2">
              Phone
            </th>
            <th width="20%" className="sort-alpha" data-priority="2">
              Email Address
            </th>
            <th width="20%" className="sort-alpha" data-priority="2">
              Actions{" "}
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(state.partners) &&
            state.partners.map((row, i) => {
              return (
                <tr data-item={row.id} key={row.id} partner={row}>
                  <td>{i + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.phone}</td>
                  <td>{row.email}</td>
                  <td>
                    <Button
                      size="sm"
                      onClick={() => props.selectPartner(row.uuid)}
                      color="primary"
                    >
                      Switch
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Col>
  );
};

export default PartnerSearch;
