import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PartnerSearch from "./PartnerSearch";

const PartnerModal = (props) => {
  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        modalTransition={{ timeout: 100 }}
        backdropTransition={{ timeout: 100 }}
        size="lg"
        toggle={props.toggle}
        className={props.className}
      >
        <ModalHeader toggle={props.toggle}>Search for Partner</ModalHeader>
        <ModalBody
          style={{
            overflowX: "auto",
          }}
        >
          <PartnerSearch
            selectPartner={props.selectPartner}
            switchToAdmin={props.switchToAdmin}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.toggle}>
            OK
          </Button>{" "}
          <Button color="secondary" onClick={props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default PartnerModal;
