import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input, Col } from "reactstrap";
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";

import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";

const PartnerLoginForm = (props) => {
  const { id, login, email, onChange, password, loading } = props;
  const [state, setState] = useState({
    username: "",
    password: "",
    showPassword: false,
  });
  return (
    <Form id={id} className="col-md-12" onSubmit={login}>
      <FormGroup className="col-md-12 input-group with-focus margin-top-10">
        <Label for="exampleEmail" className="input-group">
          Email
        </Label>
        <i className="input-group-text bg-transparent">
          <FaEnvelope />
        </i>
        <Input
          type="username"
          name="email"
          value={email}
          onChange={onChange}
          id="username"
          placeholder="Username / Email"
        />
      </FormGroup>
      <FormGroup className="col-md-12 input-group with-focus margin-top-10">
        <Label for="examplePassword" className="input-group">
          Password
        </Label>
        <i className="input-group-text bg-transparent">
          <FaLock />
        </i>
        <Input
          type={state.showPassword ? "text" : "password"}
          name="password"
          id="password"
          value={password}
          onChange={onChange}
          placeholder="Password"
        />
        <i
          className="input-group-text bg-transparent"
          onClick={() =>
            setState((prevState) => ({
              ...prevState,
              showPassword: !prevState.showPassword,
            }))
          }
        >
          {state.showPassword ? <FaEye /> : <FaEyeSlash />}
        </i>
      </FormGroup>

      <Col md={12}>
        <Button
          type="submit"
          color={"success"}
          disabled={loading}
          onClick={login}
          className={"form-control"}
        >
          {loading ? (
            <ScaleLoader
              size={5}
              height={15}
              color={"#ffffff"}
              loading={true}
            />
          ) : (
            "Login"
          )}
        </Button>
      </Col>

      <Col md={8} className="resetPassword">
        <p>
          Forgot your password?{" "}
          <Link  to="/partners/forgot_password">Reset Password</Link>
        </p>
      </Col>

      <Col md={12} className="signup">
        <p>
          Don't have an account? <Link to="/partners/sign_up">Sign up</Link> or{" "}
          <Link to="/partners/confirm_email">Confirm Your Email</Link>
        </p>
      </Col>
    </Form>
  );
};

export default PartnerLoginForm;
