import React from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";

const LoginForm = (props) => {
  const {
    id,
    email,
    onChange,
    confirm,
    confirmEmail,
    resetPassword,
    resetting,
    confirming,
  } = props;
  const handleClick = confirm ? confirmEmail : resetPassword;
  const isLoading = confirming || resetting;
  const buttonText = confirm ? "Confirm Email" : "Reset Password";
  return (
    <Form id={id} className="col-md-12">
      <FormGroup>
        <Label for="exampleEmail">Email</Label>
        <Input
          type="username"
          name="email"
          value={email}
          onChange={onChange}
          id="email"
          placeholder="Your Email Address"
        />
      </FormGroup>

      <Row>
        <Col md={5}>
          <Button
            color="success"
            onClick={handleClick}
            className="form-control"
            disabled={isLoading}
          >
            {" "}
            {isLoading ? (
              <ScaleLoader
                size={5}
                height={15}
                color="#ffffff"
                loading={true}
              />
            ) : (
              buttonText
            )}
          </Button>
        </Col>
        <Col md={4}>
          <Link to="/" className="btn btn-secondary form-control">
            Cancel
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="signup">
          <p>
            Don't have an account? <Link to="/sign_up">Sign up</Link>
          </p>
        </Col>
      </Row>
    </Form>
  );
};

export default LoginForm;
