import React, { Suspense, Fragment } from "react";
import AppNavbar from "../AppNavbar/MainAppbar";
import { Row, Col } from "reactstrap";
import DashboardTop from "./DashboardTop";
import "./Layout.scss";
import Sidebar from "../Sidebar/Sidebar";
import MainContainer from "./MainContainer";
import Routes from "./Routes";
import PageLoader from "../PageLoader";

import * as actions from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

const Layout = () => {
  return (
    <Row className="layout_container">
      <Fragment>
        <Sidebar />
        <MainContainer>
          <AppNavbar />
          <Row>
            <DashboardTop />
            <Suspense fallback={<PageLoader />}>
              <Col md={12} className={"content"}>
                <Routes />
              </Col>
            </Suspense>
          </Row>
        </MainContainer>
      </Fragment>
    </Row>
  );
};

const mapStateToProps = (state) => ({ authentication: state.authentication });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
