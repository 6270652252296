import React, { useState } from "react";
import AuthLayout from "../Layout/AuthLayout";
import axios_original from "axios";
import ForgotPasswordForm from "../ForgotPassword/ForgotPasswordForm";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";

import { baseurl } from "../misc/Axios";

const ConfirmEmail = () => {
  const [state, setState] = useState({
    email: "",
    errors: [],
    message: "",
    confirming: false,
  });
  const confirmEmailAddress = (e) => {
    console.log("CONFIRMING EMAIL");
    e.preventDefault();

    setState((prevState) => ({
      ...prevState,
      confirming: true,
    }));

    axios_original
      .post(baseurl + "/partners/confirmation", { email: state.email })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data?.message);
          setState((prevState) => ({
            ...prevState,
            confirming: false,
            email: "",
            message: response.data.message,
          }));
        }
      })
      .catch((error, errordata) => {
        setState((prevState) => ({
          ...prevState,
          confirming: false,
          errors: error.response.data.errors,
        }));
      });
  };
  const onChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      errors: [],
    }));
  };
  return (
    <AuthLayout>
      <div className="row">
        <div className="col-md-12 header">
          <h2>Confirm Email</h2>
          {state.errors && state.errors.length > 0 && (
            <div className="alert alert-danger" role="alert">
              {state.errors}
            </div>
          )}
          {state.message && (
            <div className="alert alert-success" role="alert">
              {state.message}
            </div>
          )}
        </div>
        <ForgotPasswordForm
          id="ForgotPasswordBox"
          confirm={true}
          {...state}
          confirming={state.confirming}
          onChange={onChange}
          confirmEmail={confirmEmailAddress}
        />
      </div>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({ authentication: state.authentication });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
