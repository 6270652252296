import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
} from "reactstrap";
import { FormGroup, Input } from "reactstrap";
import Col from "reactstrap/lib/Col";
import Label from "reactstrap/lib/Label";
import { connect } from "react-redux";
import { ScaleLoader } from "react-spinners";
import Alert from "reactstrap/lib/Alert";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import axiosInstance, { baseurl } from "../misc/Axios";
import { withRouter } from "react-router-dom";

const ChangePasswordModal = (props) => {
  const [state, setState] = useState({
    isModalOpen: props.isOpen,
    sizegroups: [],
    account: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    error: [],
    showPassword: false,
    isLoading: false,
  });
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isModalOpen: props.isOpen,
    }));
  }, [props]);

  const onChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      account: {
        ...prevState.account,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const changePassword = () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    axiosInstance.baseurl = baseurl;
    const data = {
      password_confirmation: state.account.newPasswordConfirmation,
      password: state.account.newPassword,
    };

    axiosInstance
      .put(
        baseurl +
          `${
            props.authentication.default_path === "/admins/"
              ? "/management"
              : "/partners"
          }/password`,
        data
      )
      .then((response) => {
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            isModalOpen: false,
            isLoading: false,
          }));
        }
      })
      .catch((e) => {
        setState((prevState) => ({
          ...prevState,
          error: e?.response?.data?.errors,
          isModalOpen: false,
          isLoading: false,
        }));
      });
  };

  return (
    <div>
      <Modal
        isOpen={state.isModalOpen}
        modalTransition={{ timeout: 100 }}
        backdropTransition={{ timeout: 100 }}
        size={"lg"}
        toggle={props.toggle}
        className={props.className}
      >
        <ModalHeader toggle={props.toggle}>Change My Password</ModalHeader>
        <ModalBody>
          {state.error && state.error.length > 0 && (
            <Col md={12}>
              <Alert color="danger">
                <h6>The Following Error(s) Occurred</h6>
                <ul>
                  {state.error.map((err, index) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              </Alert>
            </Col>
          )}

          <Form className="form-horizontal">
            <FormGroup className="col-md-12">
              <Label>New Password</Label>
              <Row>
                <span className="col-md-11">
                  <Input
                    type={state.showPassword ? "text" : "password"}
                    name="newPassword"
                    placeholder="New Password"
                    onChange={onChange}
                    value={state.account?.newPassword}
                    readOnly={state.readonly}
                  />
                </span>
                <i
                  className="col-md-1 input-group-text bg-transparent"
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      showPassword: !state.showPassword,
                    }))
                  }
                >
                  {state.showPassword ? <FaEye /> : <FaEyeSlash />}
                </i>
              </Row>
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Confirm New Password</Label>
              <Row>
                <span className="col-md-11">
                  <Input
                    type={state.showPassword ? "text" : "password"}
                    name="newPasswordConfirmation"
                    placeholder="Confirm New Password"
                    onChange={onChange}
                    value={state.account?.newPasswordConfirmation}
                    readOnly={state.readonly}
                  />
                </span>

                <i
                  className="col-md-1 input-group-text bg-transparent"
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      showPassword: !state.showPassword,
                    }))
                  }
                >
                  {state.showPassword ? <FaEye /> : <FaEyeSlash />}
                </i>
              </Row>
            </FormGroup>
            <Col md={12}>
              <Button
                className="form-control"
                color="success"
                onClick={changePassword}
              >
                {state.isLoading ? (
                  <ScaleLoader
                    size={4}
                    height={10}
                    color={"#ffffff"}
                    loading={true}
                  />
                ) : (
                  "Change Password"
                )}
              </Button>
            </Col>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({ authentication: state.authentication });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal)
);
