import { combineReducers } from "redux";
import authentication_reducer from "./authentication_reducers";
// import notificationReducer from "./notifications";
// import navigation_reducer from './navigation_reducers';

const rootreducer = combineReducers({
  authentication: authentication_reducer,
  // notifications: notificationReducer,
});

export default rootreducer;
