import React from 'react';
import PartnerDashboard from './PartnerDashboard';
import AdminDashboard from './AdminDashboard';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import {withRouter } from "react-router-dom";

const Dashboard = ({ authentication }) => {
    if (authentication.default_path === "/partners/") {
        return <PartnerDashboard />;
    } else if (authentication.default_path === "/admins/") {
        return <AdminDashboard />;
    } else {
        return "/";
    }
};

const mapStateToProps = state => ({ authentication: state.authentication });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard));
