import axios from "axios";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { logout } from "../../actions/";
import { connect } from "react-redux";
import { store } from "../../index";
import { toast } from "react-toastify";
import React from "react";
import _ from "lodash";
export var baseurl = process.env.REACT_APP_BASEURL;
export var mainBaseurl = process.env.REACT_APP_MAINBASEURL;
export const API_WS_ROOT = process.env.REACT_APP_API_WS_ROOT;
export const FRONTENDURL = process.env.REACT_APP_FRONTENDURL;

var header_information;
export const HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
const axiosInstance = axios.create({
  baseURL: mainBaseurl,
});
async function getCredentials() {
  var token = await localStorage.getItem("token");
  var uid = await localStorage.getItem("uid");
  var client = await localStorage.getItem("client");
  return { token, uid };
}
async function getheaderInformation() {
  var token = await localStorage.getItem("token");
  var uid = await localStorage.getItem("uid");
  var client = await localStorage.getItem("client");
  return { token: token, uid: uid, client: client };
}
axiosInstance.interceptors.request.use(function (config) {
  var headerInformation = getheaderInformation();
  var { token, uid, client } = getCredentials();
  config.headers = {
    "Content-Type": "application/json",
    "access-token": localStorage.getItem("token"),
    uid: localStorage.getItem("uid"),
    client: localStorage.getItem("client"),
  };
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status == 202) {
      toast.success(response.data.message);
    }
    if (response.status == 200 && !_.isEmpty(response.data.message)) {
      toast.success(response.data.message);
    }
    if (response.status == 200 && !_.isEmpty(response.data.error)) {
      toast.error(response.data.error);
    }
    return response;
  },
  function (error) {
    console.log(error.response.status === 400);
    console.log(error.response.data);
    if (error.response.status === 400 && error.response.data) {
      toast.error(errorMessages(error.response.data));
    }
    if (error.response.status === 401 && error.response.data) {
      toast.error(errorMessages(error.response.data));
      store.dispatch(logout());
    }
    if (error.response.status === 404 && error.response.data) {
      toast.error(errorMessages(error.response.data));
      store.dispatch(logout());
      // } else {
      //   console.log("Else execution");
      //   toast.error("Sorry. An error ocurred while performing an action");
    }
    return Promise.reject(error);
  }
);

export function errorMessages(errors) {
  console.log(errors);
  const messages = [];
  if (errors.message) {
    messages.push(errors.message);
  } else {
    messages.push("The Following Errors Occurred");
  }

  const errorList = errors.errors;
  const keys = Object.keys(errorList);

  keys.forEach((key) => {
    if (key !== "full_messages") {
      const errorMessage = errorList[key];
      const formattedMessage =
        typeof errorMessage === "string"
          ? errorMessage
              .replace(/_/g, " ")
              .replace(/\b\w/g, (char) => char.toUpperCase())
          : errorMessage;
      messages.push(<li key={key}>{formattedMessage}</li>);
    }
  });

  return <ul>{messages}</ul>;
}

const mapStateToProps = (state) => {
  return { user: state.authentication.user, token: state.authentication.token };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(axiosInstance);
